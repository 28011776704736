.testimonials {
  z-index: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

figure.testimonial {
  position: relative;
  overflow: hidden;
  margin: 10px;
  margin-top: 25px;
  min-width: 220px;
  width: 100%;
  color: #333;
  text-align: left;
  box-shadow: none !important;
}

figure.testimonial * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.testimonial-image {
  border-radius: 50%;
  border: 2px solid #486587;
  max-width: 100%;
  vertical-align: middle;
  height: 90px;
  width: 90px;
  margin: 40px 0 0 10px;
}

.testimonial-image img {
  vertical-align: middle;
  height: 84px;
  width: 84px;
  border-radius: 50%;
  border: 3px solid white;
  margin: 1px;
}

figure.testimonial blockquote {
  display: block;
  border-radius: 8px;
  position: relative;
  background-color: #fafafa;
  padding: 25px 50px 30px 50px;
  font-size: 0.8em;
  font-weight: 500;
  margin: 0;
  line-height: 1.6em;
  font-style: italic;
}

figure.testimonial blockquote:before,
figure.testimonial blockquote:after {
  content: "\201C";
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}

figure.testimonial blockquote:before {
  top: 25px;
  left: 20px;
}

figure.testimonial blockquote:after {
  content: "\201D";
  right: 20px;
  bottom: 0;
}

figure.testimonial .arrow {
  top: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #fafafa;
  margin: 0;
  position: absolute;
}

figure.testimonial .author {
  position: absolute;
  bottom: 45px;
  padding: 0 10px 0 120px;
  margin: 0;
  text-transform: uppercase;
  color: #ffffff;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

figure.testimonial .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 800;
}

figure.testimonial .author span {
  display: block;
  font-weight: 400;
  text-transform: none;
  color: lightgray;
  font-style: italic;
}

@media only screen and (min-width: 820px) {
  .testimonial {
    max-width: 310px;
  }
}