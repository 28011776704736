form {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
}

.form-field {
  margin-bottom: 10px;
  color: #142d4c;
  display: flex;
  flex-direction: column;
}

.form-field label {
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 20px;
}

.form-field input,
.form-field textarea {
  border-radius: 5px 5px 0 0;
  outline: none;
  border: none;
  border-bottom: 2px solid #142d4c;
  margin-bottom: 10px;
  padding: 10px 10px 8px;
  background: none;
  font-size: 16px;
  background: #DBE0E7;
}

.form-field input:disabled,
.form-field textarea:disabled {
  border-bottom: 2px solid gray;
  color: gray;
  cursor: not-allowed;
  background: lightgray;
}

.form-field textarea {
  resize: none;
  padding: 10px;
}

.form-row {
  display: flex;
  flex-direction: column;
}

.form-field-no-margin,
.form-field-no-margin input {
  margin: 0;
}

@media only screen and (min-width: 750px) {
  .form-row {
    flex-direction: row;
  }

  .flex-one {
    flex-grow: 1;
  }

  .form-row>*:not(:last-child) {
    margin-right: 10px;
  }
}

.form-button-wrap {
  display: flex;
  text-align: left;
  align-items: center;
}

.form-success-message {
  margin-left: 10px;
  text-align: left;
  font-weight: bold;
  color: #142d4c;
  width: 100%x;
}