.first-color {
  background: #ececec;
}

.second-color {
  background: #9fd3c7;
}

.second-second-color {
  background: #ffd9aa;
}

.third-color {
  background: #486587;
}

.fourth-color {
  background: #142d4c;
}

/* NAV */

.nav {
  display: flex;
  height: 40px;
  position: sticky;
  justify-content: space-between;
  top: 0;
  padding: 10px;
  background: rgba(255, 255, 255, .97);
  z-index: 10;
  transition: box-shadow 400ms;
}

.nav-logo {
  display: flex;
  align-items: center;
  height: 40px;
}

.nav-logo-image {
  height: 100%;
  padding-right: 10px;
}

.nav-logo-text-container {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.nav-logo-text {
  /*  */
  font-size: large;
}

.text-small {
  font-size: small;
}

.nav-links {
  width: 100%;
  display: none;
  align-items: flex-end;
  justify-content: flex-end;
}

.nav-links>* {
  display: block;
  color: black;
  text-decoration: none;
  font-weight: bold;
  padding: 8px 10px;
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}

.link-active-true {
  color: #142d4c;
  text-decoration: underline;
}

.nav-links>*:hover {
  background: #486587;
  color: white;
}


.nav-links>*:not(:last-child) {
  margin-right: 5px;
}

.nav-bars {
  height: 40px;
  cursor: pointer;
}

.nav-bars-image {
  height: 20px;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}

.nav-bars:hover .nav-bars-image {
  background: lightgray;
}

.side-nav-back {
  height: 100%;
  width: 100%;
  opacity: 0%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 11;
  top: 0;
  transition: all 400ms;
}

.side-nav-back-open-true {
  opacity: 100%;
}

.side-nav {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 12;
  top: 0;
  left: -228px;
  background: white;
  overflow-x: hidden;
  transition: all 400ms;
  padding: 10px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.25);
}

.side-nav-open-true {
  left: 0;
}

.side-nav-links {
  display: flex;
  flex-direction: column;
}

.side-nav-links>* {
  margin-top: 15px;
  color: black;
  text-decoration: none;
  font-weight: bold;
  padding: 8px 10px;
  background: white;
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}

.side-nav-links>*:hover {
  background: lightgray;
}

.side-nav-links>a {
  display: flex;
}

.side-nav-links>a img {
  height: 18px;
  width: 20px;
  margin-right: 10px;
}

hr {
  height: 1px;
  border: none;
  border-bottom: 1px solid lightgray;
}

@media only screen and (min-width: 700px) {
  .nav-bars {
    display: none;
  }

  .nav-links {
    display: flex;
  }
}

/* SECTIONS */

section {
  display: flex;
  flex-direction: column;
  align-content: center;
}

/* classes that belong to section tag */

.blue-section {
  background: #486587;
}

.gray-section {
  background: #ececec;
}

.green-section {
  background: #9fd3c7;
}

.dark-blue-section {
  background: #142d4c;
}

.orange-section {
  background: rgb(144, 79, 0);
}

@media only screen and (min-width: 700px) {
  .svg-wave-top {
    margin-bottom: -320px;
  }

  .svg-wave-bottom {
    margin-top: -320px;
  }

  .section-padding-bottom {
    padding-bottom: 160px !important;
  }

  .section-padding-top {
    padding-top: 160px !important;
  }

  .section-svg-wave-white {
    color: white;
  }

  .above-svg-wave {
    z-index: 1;
  }
}

@media only screen and (min-width: 1300px) {
  .section-padding-bottom {
    padding-bottom: 70px !important;
  }

  .section-padding-top {
    padding-top: 70px !important;
  }
}

.dark-blue-fill path {
  fill: #142d4c;
}

.gray-fill path {
  fill: #ececec;
}

.white-fill path {
  fill: white;
}

.color-white {
  color: white;
}

/* wraps contents in section */

.section-wrapper {
  padding: 0 30px;
  max-width: 1440px;
  align-self: center;
  width: calc(100% - 60px);
}

.section-wrapper-full-width {
  max-width: unset;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 700px) {
  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .section-wrapper {
    width: calc(100% - 60px)
  }

  .no-padding-right {
    padding-right: 0;
    width: calc(100% - 30px)
  }
}

/* HERO SECTION */

.hero-section {
  display: flex;
  flex-direction: column;
}

.hero-text-wrapper {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  width: 100%;
}

.hero-text {
  width: 100%;
}

.hero-title {
  font-size: 40px;
  margin-bottom: 15px;
}

.hero-title-home {
  margin: 0;
  color: rgb(36, 36, 36);
  font-size: 60px !important;
}

.hero-description {
  font-size: 16px;
  margin: 15px 0;
}

.hero-buttons {
  margin-top: 10px;
  display: flex;
}

.hero-image {
  height: 300px;
  background-image: url('./assets/hero-image.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 2px solid #142d4c;
}


.hero-section-home {
  background-image: url('./assets/home-header-image.jpg');
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.hero-section-home-inner-wrapper {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media only screen and (min-width: 700px) {
  .hero-section {
    display: flex;
    flex-direction: row;
    height: 500px;
  }

  .hero-text-wrapper {
    width: 40%;
    height: calc(100% - 100px);
    padding: 50px;
  }

  .hero-text {
    height: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
  }

  .hero-title {
    margin-bottom: 30px;
    font-size: 50px;
  }

  .hero-title-home {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .hero-description {
    font-size: 18px;
    margin: 10px 0 20px;
  }

  .hero-buttons:not(:last-child) {
    margin-right: 10px;
  }

  .hero-image {
    width: 60%;
    height: 100%;
    height: 500px;
    margin-bottom: 0;
    border: none;
    border-radius: 0;
  }
}

/* BUTTONS */

button,
.button {
  outline: none;
  border: 2px solid #142d4c;
  color: #142d4c;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  width: 150px;
  text-align: center;
  text-decoration: none;
  background: white;
}

button:hover,
.button:hover {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.75);
  transform: scale(1.05);
}

.button-hover-less:hover {
  transform: scale(1.01);
}

button:disabled {
  border: 2px solid gray;
  color: gray;
  cursor: not-allowed;
  background: lightgray;
}

button:disabled:hover {
  transform: scale(1);
  box-shadow: none;
}

.button-margin-right {
  margin-right: 15px;
}

.button-filled {
  background: #142d4c;
  color: white;
}

.button-orange-filled {
  border: 2px solid rgb(144, 79, 0);
  background: rgb(144, 79, 0);
  color: white
}

.button-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 0;
  padding: 7px;
  margin: 15px;
}

.button-no-width {
  width: unset;
}

/* GENERIC BLOCKS */

h2 {
  margin-bottom: 0;
  padding-bottom: 10px;
  border-bottom: 2px solid lightgray;
}

h2.no-line {
  border: none;
}

.h2-no-margin-top {
  margin-top: 0;
}

.h2-margin-bottom {
  margin-bottom: 20px;
}

.width-30 {
  width: 30%;
}

.width-40 {
  width: 40%;
}

.width-50 {
  width: 50%;
}

.width-60 {
  width: 60%;
}

.width-70 {
  width: 70%;
}

.width-100 {
  width: 100%;
}

.stacked {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  justify-content: flex-start;
  width: 100%;
}

.image-card {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe8cd;
  border: 1px solid black;
  box-shadow: -10px 10px 0 #904f00;
  margin-bottom: 20px;
}

.image-wrapper-green {
  background: black;
  box-shadow: -10px 10px 0 #1ECF5E;
}

.image-wrapper-red {
  background: white;
  box-shadow: -10px 10px 0 #F70000;
}

.image-wrapper img {
  height: 80px;
  width: calc(100% - 60px);
  padding: 30px;
}

.icon-shadow {
  filter: drop-shadow(-1.5px 2.5px 0 rgba(0, 0, 0, 1));
}

@media only screen and (min-width: 700px) {
  .image-card {
    width: 220px;
  }

  .image-card>* {
    width: 220px;
  }

  .image-wrapper {
    width: 200px;
    height: 200px;
  }

  .stacked {
    width: inherit;
  }

  .width-30,
  .width-40,
  .width-50,
  .width-60,
  .width-70,
  .width-100 {
    padding: 50px;
  }

  .stacked-row {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

/* FOOTER */

.footer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: white;
}

.footer-section {
  display: flex;
  flex-direction: column;
  margin: 5px;
  justify-content: space-between;
  align-items: center;
}

.footer-flex-grow-1 {
  flex-grow: 2;
}

.footer-flex-grow-2 {
  flex-grow: 2;
}

.footer-section>* {
  margin-bottom: 10px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.footer-links>* {
  text-decoration: none;
  color: white;
  margin-bottom: 10px;
}

@media only screen and (min-width: 700px) {
  .footer {
    margin-top: 60px;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
  }

  .footer-section {
    flex-direction: row;
  }

  .footer-section>* {
    align-self: flex-start;
  }

  .footer-links {
    align-items: flex-start;
  }
}

/* COMPOSITIONS */

.compositions-loading {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  min-width: 100%;
  font-size: 40px;
  background-color: rgba(227, 227, 227, 0.151);
  border-radius: 10px;
  color: gray;
}

.composition-text {
  color: white;
}

.composition-header-text {
  margin-top: 0;
}

.playlist {
  padding: 15px;
  background: #486587;
  border-radius: 5px;
  margin-right: auto;
}

.playlist-header {
  display: flex;
}

.playlist-icons {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.link-icon {
  width: 25px;
  height: 25px;
  padding-left: 10px;
}

.break-no-show {
  display: none;
}

.composition-listen-on {
  display: flex;
}

.composition-listen-on>*:not(:last-child) {
  margin-right: 20px;
}

.listen-on-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.listen-on-icon {
  width: 20px;
  margin-right: 5px;
}

@media only screen and (min-width: 700px) {
  .playlist {
    margin: auto;
  }

  .break-no-show {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  .iframe-playlist {
    width: 560px;
    height: 315px;
  }
}

.questions-wrapper {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.questions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.25);
}

/* COMPOSITIONS */
.featured-compositions {
  display: flex;
  margin-top: 20px;
  overflow: hidden;
  min-height: 485px;
  width: 100%;
}

.composition-card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: calc(100vw - 80px);
}

.composition-detail-wrapper {
  display: flex;
  flex-direction: column;
}

.composition-preview {
  width: 100%;
  margin-right: 20px;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  background: white;
}

.composition-info {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-wrap: wrap;
}

.composition-row-wrapper {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 50%;
}

.wrapper-unset {
  max-width: unset;
}

.composition-label,
.composition-card-text {
  color: rgb(21, 21, 21);
}

.composition-label {
  margin-top: 3px;
  font-size: small;
  font-weight: bold;
}

.composition-card-text {
  font-size: small;
  margin-top: 7px;
  margin-bottom: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.composition-audio {
  margin-top: 10px;
  width: 100%;
}

.section-no-padding-bottom {
  padding-bottom: 0;
}

.composition-row-mobile {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  align-items: center;
}

.composition-row-mobile>* {
  flex: 1;
}

@media only screen and (min-width: 500px) {
  .purchase-button {
    align-self: unset;
  }

  .composition-row-wrapper {
    margin: 0;
    max-width: unset;
  }

  .composition-info {
    margin-top: 0;
    padding-left: 8px;
  }

  .composition-label {
    font-size: medium;
  }

  .composition-card {
    width: 350px;
  }

  .composition-preview {
    min-width: 180px;
  }

  .composition-detail-wrapper {
    padding-bottom: 20px;
    flex-direction: row;
  }

  .composition-row-mobile {
    flex-direction: column;
    align-items: unset;
  }
}

.composition-cart {
  display: flex;
  align-items: center;
}

.composition-cart-icon {
  width: 12px;
  margin-right: 5px;
}

/* keep this below other comp classes to force row */
.composition-force-row {
  display: flex;
  flex-direction: row;
}

.composition-carousel-footer {
  padding-top: 10px;
  margin-top: 15px;
  border-top: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 71px;
}

.composition-dot-wrapper {
  cursor: pointer;
}

.composition-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ececec;
  margin: 5px;
  margin-top: 20px;
}

.composition-dot-active-true {
  background-color: #142d4c;
}

.composition-dot-num {
  font-size: x-small;
  color: lightgray;
  margin-left: 5px;
  margin-right: 5px;
  width: 10px;
  text-align: center;
}

.composition-dot-num-active-true {
  color: #142d4c;
  font-weight: bold;
}

.link {
  color: #486587;
}

.link:hover {
  color: #142d4c;
}

.listen-on-link {
  color: #142d4c;
  cursor: pointer;
  text-decoration: underline;
}

/* COMPOSITION FULL PAGE VIEW */

.composition-full-page {
  height: 100%;
  width: 100%;
  display: flex;
  opacity: 0%;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 12;
  background: rgba(0, 0, 0, 0.4);
  transition: all 400ms;
  cursor: pointer;
}

.composition-open {
  opacity: 0%;
  transition: all 400ms;
}

.composition-open-true {
  opacity: 100%;
}

.composition-full-page-image {
  max-height: calc(100% - 100px);
  max-width: calc(100% - 60px);
  height: max-content;
  width: max-content;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

/* 404 PAGE */

.page-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.not-found-stack {
  display: flex;
  align-items: center;
}

.not-found-title {
  font-size: 120px;
  color: rgb(161, 161, 161);
  margin-top: 20px;
  margin-bottom: 20px;
}

.not-found-title-small {
  font-size: 60px;
}

.not-found-description {
  display: flex;
  flex-direction: column;
  color: gray;
  font-size: 16px;
  margin-bottom: 30px;
}

.not-found-description>* {
  margin-bottom: 5px;
}

.checkout-modal {
  display: flex;
  flex-direction: column;
  margin: 30px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  max-width: 400px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.modal-header,
.modal-description,
.modal-detail {
  margin-bottom: 10px;
}

.modal-detail {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: small;
  color: gray;
}

.no-pointer {
  cursor: unset;
}

.close-modal {
  width: 12px;
  height: 12px;
  padding: 4px;
  background-color: rgb(236, 236, 236);
  transition: background-color 200ms;
  cursor: pointer;
  border-radius: 100px;
}

.close-modal:hover {
  background-color: lightgray;
}

/* put this under all other button props */
.purchase-button {
  font-size: 12px;
  color: white;
  background: #142d4c;
  padding: 4px 8px;
  border-radius: 100px;
  width: min-content;
  height: min-content;
  border: none;
  margin-bottom: 6px;
}

.purchase-composition-preview {
  min-width: 140px;
}

.purchase-composition-detail-wrapper {
  margin-bottom: 15px;
  flex-direction: row;
}

.purchase-composition-row-wrapper {
  max-width: unset;
  white-space: unset;
}

.card-link {
  color: black;
}

.no-link-styles {
  color: black;
  text-decoration: none;
}