.price-table {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.columns {
  float: left;
  margin-bottom: 30px;
  min-width: 275px;
  width: calc(100% - 14px);
  padding: 5px;
  border-radius: 5px;
  border: 2px solid rgb(144, 79, 0);
}

.price {
  list-style-type: none;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 5px;
}

.price .header {
  background-color: rgb(144, 79, 0);
  color: white;
  font-size: 20px;
  border-bottom: 1px solid black;
  font-weight: bold;
}

.price li {
  border-bottom: 1px solid lightgray;
  padding: 14px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.95);
}

.price .grey {
  background-color: #ffd9aa;
  color: black;
  border-bottom: 1px solid rgb(144, 79, 0);
}

.pricing-hero {
  width: calc(100% - 4px);
  border-radius: 5px;
  margin-top: 20px;
  height: 350px;
  max-height: calc(100vw - 64px);
  overflow: hidden;
  display: flex;
  border: 2px solid rgb(144, 79, 0);
}

.pricing-hero img {
  width: 100%;
  align-self: center;
}

@media only screen and (min-width: 700px) {
  .pricing-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    height: unset;
    overflow: visible;
    border: none;
  }

  .pricing-hero img {
    width: 400px;
    max-width: calc(50vw - 115px);
    height: 400px;
    max-height: calc(50vw - 115px);
    box-shadow: -15px 15px 0 rgb(144, 79, 0);
  }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .pricing-variable-padding {
    padding: calc(((50vw - 290px) / 2) - 30px);
  }
}